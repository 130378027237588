@import "~bootstrap-icons/font/bootstrap-icons";

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(~/../../assets/fonts/material-icons.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &.x18 {
        font-size: 18px;
    }
    &.x24 {
        font-size: 24px;
    }
    &.x28 {
        font-size: 28px;
    }
    &.x32 {
        font-size: 32px;
    }
    &.x36 {
        font-size: 36px;
    }
    &.x42 {
        font-size: 42px;
    }
    &.x48 {
        font-size: 48px;
    }
    &.x56 {
        font-size: 56px;
    }
    &.x64 {
        font-size: 64px;
    }
    &.x72 {
        font-size: 72px;
    }
    &.x80 {
        font-size: 80px;
    }
    &.x100 {
        font-size: 100px;
    }
}
