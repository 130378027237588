@import "variables";

$number-of-spinners: 6;
@for $i from 1 through $number-of-spinners {
    .spinner-#{$i} {
        width: 1rem * $i;
        height: 1rem * $i;
        border-width: $i * 1px + 1px;
    }
}
