@use "sass:map";
@import '~@fontsource/roboto/100.css'; // Thin
@import '~@fontsource/roboto/300.css'; // Light
@import '~@fontsource/roboto/400.css'; // Regular
//@import '~@fontsource/roboto/500.css'; // Medium
@import '~@fontsource/roboto/700.css'; // Bold
@import '~@fontsource/roboto/900.css'; // Black
@import "variables";
@import "~bootstrap/scss/mixins";


@font-face {
    font-family: 'Ubuntu';
    src: url(~/../../assets/fonts/Ubuntu-Regular.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

html,
body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    @include media-breakpoint-up(sm) {
        font-size: 14px;
    }
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 20px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 22px;
    }
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-weight: 300 !important;
}

.h1, h1 {
    font-size: $h1-font-size;
}

.h2, h2 {
    font-size: $h2-font-size;
}

.h3, h3 {
    font-size: $h3-font-size;
}

.h4, h4 {
    font-size: $h4-font-size !important;
}
