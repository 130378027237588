@import 'mixins';

html,
body {
    min-height: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.hide-on-iphone-se {
    @include iphone-se() {
        display: none;
    }
}

.show-on-iphone-se {
    display: none;
    @include iphone-se() {
        display: block;
    }
}

.outside-of-screen {
    position: absolute;
    transform: translateX(-999999px) translateY(-999999px);
}
