@import 'variables';

$step: 1rem;
@each $name, $value in $grid-breakpoints {

    @if $name=='xs' {
        // width & height steps with breakpoints
        @for $i from 1 through $size-steps-max {
            .rw-#{$name}-#{$i}, .rw-#{$i} {
                width: $i * $step;
            }
            .rh-#{$name}-#{$i}, .rh-#{$i} {
                height: $i * $step;
            }
        }
        .rw-#{$name}-unset, .rw-unset {
            width: unset;
        }
        .rh-#{$name}-unset, .rh-unset {
            height: unset;
        }
        .rw-#{$name}-auto, .rw-auto {
            width: auto;
        }
        .rh-#{$name}-auto, .rh-auto {
            height: auto;
        }
    } @else {
        @include media-breakpoint-up($name) {
            // width & height steps with breakpoints
            @for $i from 1 through $size-steps-max {
                .rw-#{$name}-#{$i} {
                    width: $i * $step;
                }
                .rh-#{$name}-#{$i} {
                    height: $i * $step;
                }
            }
            .rw-#{$name}-unset {
                width: unset;
            }
            .rh-#{$name}-unset {
                height: unset;
            }
            .rw-#{$name}-auto {
                width: auto;
            }
            .rh-#{$name}-auto {
                height: auto;
            }
        }
    }
}
