@use 'sass:map';
@import "variables";


.ip-btn {
    @extend .btn;

    box-shadow: none;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    outline: none !important;
    border: none !important;
    padding: 0.7rem 2rem;
    margin: 0.25rem;
    min-width: 10rem;

    &:active {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &[disabled="disabled"], &[disabled="true"] {
        pointer-events: none;
    }

    &.ip-btn-small {
        font-size: 16/18rem;
        padding: 0.25rem 0.8rem;
        min-width: unset;
    }

    &.ip-btn-no-upper-case {
        text-transform: unset;
    }
}


.ip-btn-light {
    @extend .ip-btn;

    background-color: $white;
    color: $primary;

    &:visited {
        background-color: $white;
        color: $primary;
    }

    &:active {
        background-color: mix($white, $primary, 100%-30%);
        color: $primary;
    }

    &:hover {
        background-color: mix($white, $primary, 100%-16%);
        color: $primary;
    }

    &[disabled="disabled"], &[disabled="true"] {
        opacity: 40%;
    }
}

.ip-btn-flat {
    @extend .ip-btn;

    background-color: $primary;
    color: $white;

    &:visited {
        background-color: $primary;
        color: $white;
    }

    &:active {
        background-color: lighten($primary, 5%);
        color: $white;
    }

    &:hover {
        background-color: darken($primary, 5%);
        color: $white;
    }

    @each $name, $color in $theme-colors {
        &.ip-btn-#{$name} {
            background-color: $color;
            color: map.get($theme-accents, $name);

            &:visited {
                background-color: $color;
                color: map.get($theme-accents, $name);
            }

            &:active {
                background-color: lighten($color, 5%);
                color: map.get($theme-accents, $name);
            }

            &:hover {
                background-color: darken($color, 5%);
                color: map.get($theme-accents, $name);
            }
        }
    }



    &[disabled="disabled"], &[disabled="true"] {
        background-color: $gray-300;
        color: $gray-500;
    }
}

.ip-btn-framed {
    @extend .ip-btn;

    background-color: transparent;

    &:visited {
        background-color: transparent;
    }

    &[disabled="disabled"], &[disabled="true"] {
        background-color: transparent;
        border: 2px solid $gray-300 !important;
        color: $gray-300;
    }

    // default as primary
    border: 2px solid $primary !important;
    color: $primary;

    &:visited {
        border: 2px solid $primary !important;
        color: $primary;
    }

    &:active {
        background-color: rgba($primary, 30%);
        border: 2px solid $primary !important;
        color: $primary;
    }

    &:hover {
        background-color: rgba($primary, 16%);
        border: 2px solid $primary !important;
        color: $primary;
    }

    @each $name, $color in $theme-colors {

        &.ip-btn-#{$name} {
            border: 2px solid $color !important;
            color: $color;

            &:visited {
                border: 2px solid $color !important;
                color: $color;
            }

            &:active {
                background-color: rgba($color, 30%);
                border: 2px solid $color !important;
                color: $color;
            }

            &:hover {
                background-color: rgba($color, 16%);
                border: 2px solid $color !important;
                color: $color;
            }
        }
    }
}

.ip-btn-transparent {
    background-color: rgba($white, 0);
    border: none !important;

    &:visited {
        background-color: rgba($white, 0);
        border: none !important;
    }

    &:active {
        background-color: rgba($white, 0);
        border: none !important;
    }

    &:hover {
        background-color: rgba($white, 0);
        border: none !important;
    }

    &:focus {
        box-shadow: none;
    }

    &[disabled="disabled"], &[disabled="true"] {
        background-color: rgba($white, 0);
        border: none !important;
    }
}

.ip-btn-icon {
    @extend .ip-btn;
    min-width: auto;
    padding: 0.7rem 1rem;

    @each $name, $color in $theme-colors {

        &.ip-btn-#{$name} {
            background-color: rgba($color, 0);
            border: none !important;
            color: rgba($color, 0.5);

            &:visited {
                background-color: rgba($color, 0);
                border: none !important;
                color: rgba($color, 0.5);
            }

            &:active {
                background-color: rgba($color, 0);
                border: none !important;
                color: $color;
            }

            &:hover {
                background-color: rgba($color, 0.03);
                border: none !important;
                color: $color;
            }
        }
    }

    &:hover {

        border: none !important;
    }

    &:focus {
        box-shadow: none;
    }
}

a {
    cursor: pointer;
    text-decoration: underline;
    outline: none !important;
    border: none !important;
    color: $primary;

    &:visited {
        color: $primary;
    }

    &:active {
        color: $black;
    }

    &:hover {
        color: $black;
    }

    >.bi {
        text-decoration: none;
        margin-left: 0.2em;
    }
}
