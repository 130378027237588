@use 'sass:map';
@import 'variables';

$titleFontSize: 2rem;

.modal {
    .modal-content {
        min-width: 30rem;
        max-width: unset;
        color: black;
        padding: 0 0;
        border: none;
        overflow: hidden;

        .modal-header {
            position: relative;
            border: none;
            padding: 0;
            justify-content: center;
            @extend .bi; // allow to add background icon on the left

            .modal-title {
                margin: 2.25rem 3rem;
                line-height: $h4-font-size;
                padding: 0;
                flex-grow: 1;
                text-align: center;
                @extend .bi; // allow to add background icon on the right

                &::before {
                    // background icon of header on the right side
                    content: "";
                    position: absolute;
                    right: 4rem;
                    bottom: 0.3rem;
                    font-size: 2.25rem;
                    color: rgba($white, 30%);
                    transform: rotate(-16deg);
                }
            }

            &::before {
                // background icon of header on the left side
                content: "";
                position: absolute;
                left: 1rem;
                top: -1rem;
                font-size: 5.625rem;
                color: rgba($white, 30%);
                transform: rotate(13deg);
            }

            .close-button {
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                background-color: transparentize($black, 1);
                padding: 0.625rem;
                line-height: 1;
                font-size: 1.25rem;
                color: rgba($white, 50%);
                border: none;
                outline: none !important;
                transition: color 0.3s;

                @extend .bi;
                @extend .bi-x-lg;

                &:hover {
                    color: rgba($white, 100%);
                }
            }
        }

        .modal-body {
            padding: 2rem 2rem 3rem 2rem;
        }

        .modal-footer {
            padding: 0 2rem 2rem 2rem;
            justify-content: center;
            border: none;

            button {
                @extend .ip-btn;

                &:not([class^="cancel-btn"]) {
                    @extend .ip-btn-framed;
                }
            }

            button.cancel-btn {
                @extend .ip-btn-flat;
                @extend .ip-btn-danger;
            }
        }

    }
}

// styles overrides for all modal types
@each $name, $data in $modal-types {
    $color: map.get($data, color);
    $color-name: map.get($data, color-name);
    $accent: map.get($data, accent);
    $icon: map.get($data, icon);

    .modal.#{$name}-modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    background-color: $color;
                    color: $accent;
                    @extend .bi-#{$icon};

                    .modal-title {
                        @extend .bi-#{$icon};
                    }
                }

                .modal-footer {
                    button:not([class^="close-button"]):not([class^="cancel-btn"]) {
                        @extend .ip-btn-#{$color-name};
                    }
                }
            }
        }
    }
}
