@use 'sass:map';

$primary: #0071bc;
$secondary: #29abe2;
$success: #5aba7a;
$danger: #d32432;
$warning: #f6a35c;
$info: #435ca8;
$accent1: #098cc6;
$accent2: #2389ad;
$accent3: #0067a0;
$special1: #ff1d25;
$special2: #fcee21;

$white: #ffffff;
$gray-100: #f2f2f2;
$gray-200: #e6e6e6;
$gray-300: #cccccc;
$gray-400: #b3b3b3;
$gray-500: #999999;
$gray-600: #808080;
$gray-700: #666666;
$gray-800: #4d4d4d;
$gray-900: #333333;
$black: #000000;

$light: $gray-100;
$dark: $gray-800;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "accent1": $accent1,
    "accent2": $accent2,
    "accent3": $accent3,
    "special1": $special1,
    "special2": $special2,
    "white": $white,
    "black": $black,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900
);
$theme-accents: (
    "primary": $white,
    "secondary": $white,
    "success": $white,
    "info": $white,
    "warning": $white,
    "danger": $white,
    "light": $black,
    "dark": $white,
    "accent1": $white,
    "accent2": $white,
    "accent3": $white,
    "special1": $white,
    "special2": $black,
    "white": $black,
    "black": $white,
    "gray-100": $black,
    "gray-200": $black,
    "gray-300": $black,
    "gray-400": $black,
    "gray-500": $black,
    "gray-600": $white,
    "gray-700": $white,
    "gray-800": $white,
    "gray-900": $white
);
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
);

$accent: (
    base: $accent2,
);

$grid-breakpoints: (
    xs: 0,
    sm: 560px,
    md: 768px,
    lg: 1024px,
    xl: 1600px,
    xxl: 1920px,
    xxxl: 2300px
);

$container-max-widths: (
    sm: 670px,
    md: 900px,
    lg: 1600px,
    xl: 1920px,
    xxl: 2200px
);

$spinner-border-width-sm: 2px;
$enable-negative-margins: true;

$display-font-sizes: (
    1: 5rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem
);

$display-font-weight: 300;
$display-line-height: 1.2;

$h1-font-size: 3.25rem;
$h2-font-size: 2.75rem;
$h3-font-size: 2.25rem;
$h4-font-size: 2rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.25rem;
$small-font-size: 0.875em;
$sub-sup-font-size: .75em;

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$spacer: 1rem;

$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
    8: $spacer * 3.5,
    9: $spacer * 4,
    10: $spacer * 5,
    11: $spacer * 6,
    12: $spacer * 7,
    13: $spacer * 8,
    14: $spacer * 9,
    15: $spacer * 10,
    16: $spacer * 11,
    17: $spacer * 12,
    18: $spacer * 13,
    19: $spacer * 14,
    20: $spacer * 15,
    21: $spacer * 20,
    22: $spacer * 25,
    23: $spacer * 30,
    24: $spacer * 35,
    25: $spacer * 40,
    26: $spacer * 45
);

// Internal variables
$size-steps-max: 36;

$bootstrap-icons-map: (
    "123": "\f67f",
    "alarm-fill": "\f101",
    "alarm": "\f102",
    "align-bottom": "\f103",
    "align-center": "\f104",
    "align-end": "\f105",
    "align-middle": "\f106",
    "align-start": "\f107",
    "align-top": "\f108",
    "alt": "\f109",
    "app-indicator": "\f10a",
    "app": "\f10b",
    "archive-fill": "\f10c",
    "archive": "\f10d",
    "arrow-90deg-down": "\f10e",
    "arrow-90deg-left": "\f10f",
    "arrow-90deg-right": "\f110",
    "arrow-90deg-up": "\f111",
    "arrow-bar-down": "\f112",
    "arrow-bar-left": "\f113",
    "arrow-bar-right": "\f114",
    "arrow-bar-up": "\f115",
    "arrow-clockwise": "\f116",
    "arrow-counterclockwise": "\f117",
    "arrow-down-circle-fill": "\f118",
    "arrow-down-circle": "\f119",
    "arrow-down-left-circle-fill": "\f11a",
    "arrow-down-left-circle": "\f11b",
    "arrow-down-left-square-fill": "\f11c",
    "arrow-down-left-square": "\f11d",
    "arrow-down-left": "\f11e",
    "arrow-down-right-circle-fill": "\f11f",
    "arrow-down-right-circle": "\f120",
    "arrow-down-right-square-fill": "\f121",
    "arrow-down-right-square": "\f122",
    "arrow-down-right": "\f123",
    "arrow-down-short": "\f124",
    "arrow-down-square-fill": "\f125",
    "arrow-down-square": "\f126",
    "arrow-down-up": "\f127",
    "arrow-down": "\f128",
    "arrow-left-circle-fill": "\f129",
    "arrow-left-circle": "\f12a",
    "arrow-left-right": "\f12b",
    "arrow-left-short": "\f12c",
    "arrow-left-square-fill": "\f12d",
    "arrow-left-square": "\f12e",
    "arrow-left": "\f12f",
    "arrow-repeat": "\f130",
    "arrow-return-left": "\f131",
    "arrow-return-right": "\f132",
    "arrow-right-circle-fill": "\f133",
    "arrow-right-circle": "\f134",
    "arrow-right-short": "\f135",
    "arrow-right-square-fill": "\f136",
    "arrow-right-square": "\f137",
    "arrow-right": "\f138",
    "arrow-up-circle-fill": "\f139",
    "arrow-up-circle": "\f13a",
    "arrow-up-left-circle-fill": "\f13b",
    "arrow-up-left-circle": "\f13c",
    "arrow-up-left-square-fill": "\f13d",
    "arrow-up-left-square": "\f13e",
    "arrow-up-left": "\f13f",
    "arrow-up-right-circle-fill": "\f140",
    "arrow-up-right-circle": "\f141",
    "arrow-up-right-square-fill": "\f142",
    "arrow-up-right-square": "\f143",
    "arrow-up-right": "\f144",
    "arrow-up-short": "\f145",
    "arrow-up-square-fill": "\f146",
    "arrow-up-square": "\f147",
    "arrow-up": "\f148",
    "arrows-angle-contract": "\f149",
    "arrows-angle-expand": "\f14a",
    "arrows-collapse": "\f14b",
    "arrows-expand": "\f14c",
    "arrows-fullscreen": "\f14d",
    "arrows-move": "\f14e",
    "aspect-ratio-fill": "\f14f",
    "aspect-ratio": "\f150",
    "asterisk": "\f151",
    "at": "\f152",
    "award-fill": "\f153",
    "award": "\f154",
    "back": "\f155",
    "backspace-fill": "\f156",
    "backspace-reverse-fill": "\f157",
    "backspace-reverse": "\f158",
    "backspace": "\f159",
    "badge-3d-fill": "\f15a",
    "badge-3d": "\f15b",
    "badge-4k-fill": "\f15c",
    "badge-4k": "\f15d",
    "badge-8k-fill": "\f15e",
    "badge-8k": "\f15f",
    "badge-ad-fill": "\f160",
    "badge-ad": "\f161",
    "badge-ar-fill": "\f162",
    "badge-ar": "\f163",
    "badge-cc-fill": "\f164",
    "badge-cc": "\f165",
    "badge-hd-fill": "\f166",
    "badge-hd": "\f167",
    "badge-tm-fill": "\f168",
    "badge-tm": "\f169",
    "badge-vo-fill": "\f16a",
    "badge-vo": "\f16b",
    "badge-vr-fill": "\f16c",
    "badge-vr": "\f16d",
    "badge-wc-fill": "\f16e",
    "badge-wc": "\f16f",
    "bag-check-fill": "\f170",
    "bag-check": "\f171",
    "bag-dash-fill": "\f172",
    "bag-dash": "\f173",
    "bag-fill": "\f174",
    "bag-plus-fill": "\f175",
    "bag-plus": "\f176",
    "bag-x-fill": "\f177",
    "bag-x": "\f178",
    "bag": "\f179",
    "bar-chart-fill": "\f17a",
    "bar-chart-line-fill": "\f17b",
    "bar-chart-line": "\f17c",
    "bar-chart-steps": "\f17d",
    "bar-chart": "\f17e",
    "basket-fill": "\f17f",
    "basket": "\f180",
    "basket2-fill": "\f181",
    "basket2": "\f182",
    "basket3-fill": "\f183",
    "basket3": "\f184",
    "battery-charging": "\f185",
    "battery-full": "\f186",
    "battery-half": "\f187",
    "battery": "\f188",
    "bell-fill": "\f189",
    "bell": "\f18a",
    "bezier": "\f18b",
    "bezier2": "\f18c",
    "bicycle": "\f18d",
    "binoculars-fill": "\f18e",
    "binoculars": "\f18f",
    "blockquote-left": "\f190",
    "blockquote-right": "\f191",
    "book-fill": "\f192",
    "book-half": "\f193",
    "book": "\f194",
    "bookmark-check-fill": "\f195",
    "bookmark-check": "\f196",
    "bookmark-dash-fill": "\f197",
    "bookmark-dash": "\f198",
    "bookmark-fill": "\f199",
    "bookmark-heart-fill": "\f19a",
    "bookmark-heart": "\f19b",
    "bookmark-plus-fill": "\f19c",
    "bookmark-plus": "\f19d",
    "bookmark-star-fill": "\f19e",
    "bookmark-star": "\f19f",
    "bookmark-x-fill": "\f1a0",
    "bookmark-x": "\f1a1",
    "bookmark": "\f1a2",
    "bookmarks-fill": "\f1a3",
    "bookmarks": "\f1a4",
    "bookshelf": "\f1a5",
    "bootstrap-fill": "\f1a6",
    "bootstrap-reboot": "\f1a7",
    "bootstrap": "\f1a8",
    "border-all": "\f1a9",
    "border-bottom": "\f1aa",
    "border-center": "\f1ab",
    "border-inner": "\f1ac",
    "border-left": "\f1ad",
    "border-middle": "\f1ae",
    "border-outer": "\f1af",
    "border-right": "\f1b0",
    "border-style": "\f1b1",
    "border-top": "\f1b2",
    "border-width": "\f1b3",
    "border": "\f1b4",
    "bounding-box-circles": "\f1b5",
    "bounding-box": "\f1b6",
    "box-arrow-down-left": "\f1b7",
    "box-arrow-down-right": "\f1b8",
    "box-arrow-down": "\f1b9",
    "box-arrow-in-down-left": "\f1ba",
    "box-arrow-in-down-right": "\f1bb",
    "box-arrow-in-down": "\f1bc",
    "box-arrow-in-left": "\f1bd",
    "box-arrow-in-right": "\f1be",
    "box-arrow-in-up-left": "\f1bf",
    "box-arrow-in-up-right": "\f1c0",
    "box-arrow-in-up": "\f1c1",
    "box-arrow-left": "\f1c2",
    "box-arrow-right": "\f1c3",
    "box-arrow-up-left": "\f1c4",
    "box-arrow-up-right": "\f1c5",
    "box-arrow-up": "\f1c6",
    "box-seam": "\f1c7",
    "box": "\f1c8",
    "braces": "\f1c9",
    "bricks": "\f1ca",
    "briefcase-fill": "\f1cb",
    "briefcase": "\f1cc",
    "brightness-alt-high-fill": "\f1cd",
    "brightness-alt-high": "\f1ce",
    "brightness-alt-low-fill": "\f1cf",
    "brightness-alt-low": "\f1d0",
    "brightness-high-fill": "\f1d1",
    "brightness-high": "\f1d2",
    "brightness-low-fill": "\f1d3",
    "brightness-low": "\f1d4",
    "broadcast-pin": "\f1d5",
    "broadcast": "\f1d6",
    "brush-fill": "\f1d7",
    "brush": "\f1d8",
    "bucket-fill": "\f1d9",
    "bucket": "\f1da",
    "bug-fill": "\f1db",
    "bug": "\f1dc",
    "building": "\f1dd",
    "bullseye": "\f1de",
    "calculator-fill": "\f1df",
    "calculator": "\f1e0",
    "calendar-check-fill": "\f1e1",
    "calendar-check": "\f1e2",
    "calendar-date-fill": "\f1e3",
    "calendar-date": "\f1e4",
    "calendar-day-fill": "\f1e5",
    "calendar-day": "\f1e6",
    "calendar-event-fill": "\f1e7",
    "calendar-event": "\f1e8",
    "calendar-fill": "\f1e9",
    "calendar-minus-fill": "\f1ea",
    "calendar-minus": "\f1eb",
    "calendar-month-fill": "\f1ec",
    "calendar-month": "\f1ed",
    "calendar-plus-fill": "\f1ee",
    "calendar-plus": "\f1ef",
    "calendar-range-fill": "\f1f0",
    "calendar-range": "\f1f1",
    "calendar-week-fill": "\f1f2",
    "calendar-week": "\f1f3",
    "calendar-x-fill": "\f1f4",
    "calendar-x": "\f1f5",
    "calendar": "\f1f6",
    "calendar2-check-fill": "\f1f7",
    "calendar2-check": "\f1f8",
    "calendar2-date-fill": "\f1f9",
    "calendar2-date": "\f1fa",
    "calendar2-day-fill": "\f1fb",
    "calendar2-day": "\f1fc",
    "calendar2-event-fill": "\f1fd",
    "calendar2-event": "\f1fe",
    "calendar2-fill": "\f1ff",
    "calendar2-minus-fill": "\f200",
    "calendar2-minus": "\f201",
    "calendar2-month-fill": "\f202",
    "calendar2-month": "\f203",
    "calendar2-plus-fill": "\f204",
    "calendar2-plus": "\f205",
    "calendar2-range-fill": "\f206",
    "calendar2-range": "\f207",
    "calendar2-week-fill": "\f208",
    "calendar2-week": "\f209",
    "calendar2-x-fill": "\f20a",
    "calendar2-x": "\f20b",
    "calendar2": "\f20c",
    "calendar3-event-fill": "\f20d",
    "calendar3-event": "\f20e",
    "calendar3-fill": "\f20f",
    "calendar3-range-fill": "\f210",
    "calendar3-range": "\f211",
    "calendar3-week-fill": "\f212",
    "calendar3-week": "\f213",
    "calendar3": "\f214",
    "calendar4-event": "\f215",
    "calendar4-range": "\f216",
    "calendar4-week": "\f217",
    "calendar4": "\f218",
    "camera-fill": "\f219",
    "camera-reels-fill": "\f21a",
    "camera-reels": "\f21b",
    "camera-video-fill": "\f21c",
    "camera-video-off-fill": "\f21d",
    "camera-video-off": "\f21e",
    "camera-video": "\f21f",
    "camera": "\f220",
    "camera2": "\f221",
    "capslock-fill": "\f222",
    "capslock": "\f223",
    "card-checklist": "\f224",
    "card-heading": "\f225",
    "card-image": "\f226",
    "card-list": "\f227",
    "card-text": "\f228",
    "caret-down-fill": "\f229",
    "caret-down-square-fill": "\f22a",
    "caret-down-square": "\f22b",
    "caret-down": "\f22c",
    "caret-left-fill": "\f22d",
    "caret-left-square-fill": "\f22e",
    "caret-left-square": "\f22f",
    "caret-left": "\f230",
    "caret-right-fill": "\f231",
    "caret-right-square-fill": "\f232",
    "caret-right-square": "\f233",
    "caret-right": "\f234",
    "caret-up-fill": "\f235",
    "caret-up-square-fill": "\f236",
    "caret-up-square": "\f237",
    "caret-up": "\f238",
    "cart-check-fill": "\f239",
    "cart-check": "\f23a",
    "cart-dash-fill": "\f23b",
    "cart-dash": "\f23c",
    "cart-fill": "\f23d",
    "cart-plus-fill": "\f23e",
    "cart-plus": "\f23f",
    "cart-x-fill": "\f240",
    "cart-x": "\f241",
    "cart": "\f242",
    "cart2": "\f243",
    "cart3": "\f244",
    "cart4": "\f245",
    "cash-stack": "\f246",
    "cash": "\f247",
    "cast": "\f248",
    "chat-dots-fill": "\f249",
    "chat-dots": "\f24a",
    "chat-fill": "\f24b",
    "chat-left-dots-fill": "\f24c",
    "chat-left-dots": "\f24d",
    "chat-left-fill": "\f24e",
    "chat-left-quote-fill": "\f24f",
    "chat-left-quote": "\f250",
    "chat-left-text-fill": "\f251",
    "chat-left-text": "\f252",
    "chat-left": "\f253",
    "chat-quote-fill": "\f254",
    "chat-quote": "\f255",
    "chat-right-dots-fill": "\f256",
    "chat-right-dots": "\f257",
    "chat-right-fill": "\f258",
    "chat-right-quote-fill": "\f259",
    "chat-right-quote": "\f25a",
    "chat-right-text-fill": "\f25b",
    "chat-right-text": "\f25c",
    "chat-right": "\f25d",
    "chat-square-dots-fill": "\f25e",
    "chat-square-dots": "\f25f",
    "chat-square-fill": "\f260",
    "chat-square-quote-fill": "\f261",
    "chat-square-quote": "\f262",
    "chat-square-text-fill": "\f263",
    "chat-square-text": "\f264",
    "chat-square": "\f265",
    "chat-text-fill": "\f266",
    "chat-text": "\f267",
    "chat": "\f268",
    "check-all": "\f269",
    "check-circle-fill": "\f26a",
    "check-circle": "\f26b",
    "check-square-fill": "\f26c",
    "check-square": "\f26d",
    "check": "\f26e",
    "check2-all": "\f26f",
    "check2-circle": "\f270",
    "check2-square": "\f271",
    "check2": "\f272",
    "chevron-bar-contract": "\f273",
    "chevron-bar-down": "\f274",
    "chevron-bar-expand": "\f275",
    "chevron-bar-left": "\f276",
    "chevron-bar-right": "\f277",
    "chevron-bar-up": "\f278",
    "chevron-compact-down": "\f279",
    "chevron-compact-left": "\f27a",
    "chevron-compact-right": "\f27b",
    "chevron-compact-up": "\f27c",
    "chevron-contract": "\f27d",
    "chevron-double-down": "\f27e",
    "chevron-double-left": "\f27f",
    "chevron-double-right": "\f280",
    "chevron-double-up": "\f281",
    "chevron-down": "\f282",
    "chevron-expand": "\f283",
    "chevron-left": "\f284",
    "chevron-right": "\f285",
    "chevron-up": "\f286",
    "circle-fill": "\f287",
    "circle-half": "\f288",
    "circle-square": "\f289",
    "circle": "\f28a",
    "clipboard-check": "\f28b",
    "clipboard-data": "\f28c",
    "clipboard-minus": "\f28d",
    "clipboard-plus": "\f28e",
    "clipboard-x": "\f28f",
    "clipboard": "\f290",
    "clock-fill": "\f291",
    "clock-history": "\f292",
    "clock": "\f293",
    "cloud-arrow-down-fill": "\f294",
    "cloud-arrow-down": "\f295",
    "cloud-arrow-up-fill": "\f296",
    "cloud-arrow-up": "\f297",
    "cloud-check-fill": "\f298",
    "cloud-check": "\f299",
    "cloud-download-fill": "\f29a",
    "cloud-download": "\f29b",
    "cloud-drizzle-fill": "\f29c",
    "cloud-drizzle": "\f29d",
    "cloud-fill": "\f29e",
    "cloud-fog-fill": "\f29f",
    "cloud-fog": "\f2a0",
    "cloud-fog2-fill": "\f2a1",
    "cloud-fog2": "\f2a2",
    "cloud-hail-fill": "\f2a3",
    "cloud-hail": "\f2a4",
    "cloud-haze-1": "\f2a5",
    "cloud-haze-fill": "\f2a6",
    "cloud-haze": "\f2a7",
    "cloud-haze2-fill": "\f2a8",
    "cloud-lightning-fill": "\f2a9",
    "cloud-lightning-rain-fill": "\f2aa",
    "cloud-lightning-rain": "\f2ab",
    "cloud-lightning": "\f2ac",
    "cloud-minus-fill": "\f2ad",
    "cloud-minus": "\f2ae",
    "cloud-moon-fill": "\f2af",
    "cloud-moon": "\f2b0",
    "cloud-plus-fill": "\f2b1",
    "cloud-plus": "\f2b2",
    "cloud-rain-fill": "\f2b3",
    "cloud-rain-heavy-fill": "\f2b4",
    "cloud-rain-heavy": "\f2b5",
    "cloud-rain": "\f2b6",
    "cloud-slash-fill": "\f2b7",
    "cloud-slash": "\f2b8",
    "cloud-sleet-fill": "\f2b9",
    "cloud-sleet": "\f2ba",
    "cloud-snow-fill": "\f2bb",
    "cloud-snow": "\f2bc",
    "cloud-sun-fill": "\f2bd",
    "cloud-sun": "\f2be",
    "cloud-upload-fill": "\f2bf",
    "cloud-upload": "\f2c0",
    "cloud": "\f2c1",
    "clouds-fill": "\f2c2",
    "clouds": "\f2c3",
    "cloudy-fill": "\f2c4",
    "cloudy": "\f2c5",
    "code-slash": "\f2c6",
    "code-square": "\f2c7",
    "code": "\f2c8",
    "collection-fill": "\f2c9",
    "collection-play-fill": "\f2ca",
    "collection-play": "\f2cb",
    "collection": "\f2cc",
    "columns-gap": "\f2cd",
    "columns": "\f2ce",
    "command": "\f2cf",
    "compass-fill": "\f2d0",
    "compass": "\f2d1",
    "cone-striped": "\f2d2",
    "cone": "\f2d3",
    "controller": "\f2d4",
    "cpu-fill": "\f2d5",
    "cpu": "\f2d6",
    "credit-card-2-back-fill": "\f2d7",
    "credit-card-2-back": "\f2d8",
    "credit-card-2-front-fill": "\f2d9",
    "credit-card-2-front": "\f2da",
    "credit-card-fill": "\f2db",
    "credit-card": "\f2dc",
    "crop": "\f2dd",
    "cup-fill": "\f2de",
    "cup-straw": "\f2df",
    "cup": "\f2e0",
    "cursor-fill": "\f2e1",
    "cursor-text": "\f2e2",
    "cursor": "\f2e3",
    "dash-circle-dotted": "\f2e4",
    "dash-circle-fill": "\f2e5",
    "dash-circle": "\f2e6",
    "dash-square-dotted": "\f2e7",
    "dash-square-fill": "\f2e8",
    "dash-square": "\f2e9",
    "dash": "\f2ea",
    "diagram-2-fill": "\f2eb",
    "diagram-2": "\f2ec",
    "diagram-3-fill": "\f2ed",
    "diagram-3": "\f2ee",
    "diamond-fill": "\f2ef",
    "diamond-half": "\f2f0",
    "diamond": "\f2f1",
    "dice-1-fill": "\f2f2",
    "dice-1": "\f2f3",
    "dice-2-fill": "\f2f4",
    "dice-2": "\f2f5",
    "dice-3-fill": "\f2f6",
    "dice-3": "\f2f7",
    "dice-4-fill": "\f2f8",
    "dice-4": "\f2f9",
    "dice-5-fill": "\f2fa",
    "dice-5": "\f2fb",
    "dice-6-fill": "\f2fc",
    "dice-6": "\f2fd",
    "disc-fill": "\f2fe",
    "disc": "\f2ff",
    "discord": "\f300",
    "display-fill": "\f301",
    "display": "\f302",
    "distribute-horizontal": "\f303",
    "distribute-vertical": "\f304",
    "door-closed-fill": "\f305",
    "door-closed": "\f306",
    "door-open-fill": "\f307",
    "door-open": "\f308",
    "dot": "\f309",
    "download": "\f30a",
    "droplet-fill": "\f30b",
    "droplet-half": "\f30c",
    "droplet": "\f30d",
    "earbuds": "\f30e",
    "easel-fill": "\f30f",
    "easel": "\f310",
    "egg-fill": "\f311",
    "egg-fried": "\f312",
    "egg": "\f313",
    "eject-fill": "\f314",
    "eject": "\f315",
    "emoji-angry-fill": "\f316",
    "emoji-angry": "\f317",
    "emoji-dizzy-fill": "\f318",
    "emoji-dizzy": "\f319",
    "emoji-expressionless-fill": "\f31a",
    "emoji-expressionless": "\f31b",
    "emoji-frown-fill": "\f31c",
    "emoji-frown": "\f31d",
    "emoji-heart-eyes-fill": "\f31e",
    "emoji-heart-eyes": "\f31f",
    "emoji-laughing-fill": "\f320",
    "emoji-laughing": "\f321",
    "emoji-neutral-fill": "\f322",
    "emoji-neutral": "\f323",
    "emoji-smile-fill": "\f324",
    "emoji-smile-upside-down-fill": "\f325",
    "emoji-smile-upside-down": "\f326",
    "emoji-smile": "\f327",
    "emoji-sunglasses-fill": "\f328",
    "emoji-sunglasses": "\f329",
    "emoji-wink-fill": "\f32a",
    "emoji-wink": "\f32b",
    "envelope-fill": "\f32c",
    "envelope-open-fill": "\f32d",
    "envelope-open": "\f32e",
    "envelope": "\f32f",
    "eraser-fill": "\f330",
    "eraser": "\f331",
    "exclamation-circle-fill": "\f332",
    "exclamation-circle": "\f333",
    "exclamation-diamond-fill": "\f334",
    "exclamation-diamond": "\f335",
    "exclamation-octagon-fill": "\f336",
    "exclamation-octagon": "\f337",
    "exclamation-square-fill": "\f338",
    "exclamation-square": "\f339",
    "exclamation-triangle-fill": "\f33a",
    "exclamation-triangle": "\f33b",
    "exclamation": "\f33c",
    "exclude": "\f33d",
    "eye-fill": "\f33e",
    "eye-slash-fill": "\f33f",
    "eye-slash": "\f340",
    "eye": "\f341",
    "eyedropper": "\f342",
    "eyeglasses": "\f343",
    "facebook": "\f344",
    "file-arrow-down-fill": "\f345",
    "file-arrow-down": "\f346",
    "file-arrow-up-fill": "\f347",
    "file-arrow-up": "\f348",
    "file-bar-graph-fill": "\f349",
    "file-bar-graph": "\f34a",
    "file-binary-fill": "\f34b",
    "file-binary": "\f34c",
    "file-break-fill": "\f34d",
    "file-break": "\f34e",
    "file-check-fill": "\f34f",
    "file-check": "\f350",
    "file-code-fill": "\f351",
    "file-code": "\f352",
    "file-diff-fill": "\f353",
    "file-diff": "\f354",
    "file-earmark-arrow-down-fill": "\f355",
    "file-earmark-arrow-down": "\f356",
    "file-earmark-arrow-up-fill": "\f357",
    "file-earmark-arrow-up": "\f358",
    "file-earmark-bar-graph-fill": "\f359",
    "file-earmark-bar-graph": "\f35a",
    "file-earmark-binary-fill": "\f35b",
    "file-earmark-binary": "\f35c",
    "file-earmark-break-fill": "\f35d",
    "file-earmark-break": "\f35e",
    "file-earmark-check-fill": "\f35f",
    "file-earmark-check": "\f360",
    "file-earmark-code-fill": "\f361",
    "file-earmark-code": "\f362",
    "file-earmark-diff-fill": "\f363",
    "file-earmark-diff": "\f364",
    "file-earmark-easel-fill": "\f365",
    "file-earmark-easel": "\f366",
    "file-earmark-excel-fill": "\f367",
    "file-earmark-excel": "\f368",
    "file-earmark-fill": "\f369",
    "file-earmark-font-fill": "\f36a",
    "file-earmark-font": "\f36b",
    "file-earmark-image-fill": "\f36c",
    "file-earmark-image": "\f36d",
    "file-earmark-lock-fill": "\f36e",
    "file-earmark-lock": "\f36f",
    "file-earmark-lock2-fill": "\f370",
    "file-earmark-lock2": "\f371",
    "file-earmark-medical-fill": "\f372",
    "file-earmark-medical": "\f373",
    "file-earmark-minus-fill": "\f374",
    "file-earmark-minus": "\f375",
    "file-earmark-music-fill": "\f376",
    "file-earmark-music": "\f377",
    "file-earmark-person-fill": "\f378",
    "file-earmark-person": "\f379",
    "file-earmark-play-fill": "\f37a",
    "file-earmark-play": "\f37b",
    "file-earmark-plus-fill": "\f37c",
    "file-earmark-plus": "\f37d",
    "file-earmark-post-fill": "\f37e",
    "file-earmark-post": "\f37f",
    "file-earmark-ppt-fill": "\f380",
    "file-earmark-ppt": "\f381",
    "file-earmark-richtext-fill": "\f382",
    "file-earmark-richtext": "\f383",
    "file-earmark-ruled-fill": "\f384",
    "file-earmark-ruled": "\f385",
    "file-earmark-slides-fill": "\f386",
    "file-earmark-slides": "\f387",
    "file-earmark-spreadsheet-fill": "\f388",
    "file-earmark-spreadsheet": "\f389",
    "file-earmark-text-fill": "\f38a",
    "file-earmark-text": "\f38b",
    "file-earmark-word-fill": "\f38c",
    "file-earmark-word": "\f38d",
    "file-earmark-x-fill": "\f38e",
    "file-earmark-x": "\f38f",
    "file-earmark-zip-fill": "\f390",
    "file-earmark-zip": "\f391",
    "file-earmark": "\f392",
    "file-easel-fill": "\f393",
    "file-easel": "\f394",
    "file-excel-fill": "\f395",
    "file-excel": "\f396",
    "file-fill": "\f397",
    "file-font-fill": "\f398",
    "file-font": "\f399",
    "file-image-fill": "\f39a",
    "file-image": "\f39b",
    "file-lock-fill": "\f39c",
    "file-lock": "\f39d",
    "file-lock2-fill": "\f39e",
    "file-lock2": "\f39f",
    "file-medical-fill": "\f3a0",
    "file-medical": "\f3a1",
    "file-minus-fill": "\f3a2",
    "file-minus": "\f3a3",
    "file-music-fill": "\f3a4",
    "file-music": "\f3a5",
    "file-person-fill": "\f3a6",
    "file-person": "\f3a7",
    "file-play-fill": "\f3a8",
    "file-play": "\f3a9",
    "file-plus-fill": "\f3aa",
    "file-plus": "\f3ab",
    "file-post-fill": "\f3ac",
    "file-post": "\f3ad",
    "file-ppt-fill": "\f3ae",
    "file-ppt": "\f3af",
    "file-richtext-fill": "\f3b0",
    "file-richtext": "\f3b1",
    "file-ruled-fill": "\f3b2",
    "file-ruled": "\f3b3",
    "file-slides-fill": "\f3b4",
    "file-slides": "\f3b5",
    "file-spreadsheet-fill": "\f3b6",
    "file-spreadsheet": "\f3b7",
    "file-text-fill": "\f3b8",
    "file-text": "\f3b9",
    "file-word-fill": "\f3ba",
    "file-word": "\f3bb",
    "file-x-fill": "\f3bc",
    "file-x": "\f3bd",
    "file-zip-fill": "\f3be",
    "file-zip": "\f3bf",
    "file": "\f3c0",
    "files-alt": "\f3c1",
    "files": "\f3c2",
    "film": "\f3c3",
    "filter-circle-fill": "\f3c4",
    "filter-circle": "\f3c5",
    "filter-left": "\f3c6",
    "filter-right": "\f3c7",
    "filter-square-fill": "\f3c8",
    "filter-square": "\f3c9",
    "filter": "\f3ca",
    "flag-fill": "\f3cb",
    "flag": "\f3cc",
    "flower1": "\f3cd",
    "flower2": "\f3ce",
    "flower3": "\f3cf",
    "folder-check": "\f3d0",
    "folder-fill": "\f3d1",
    "folder-minus": "\f3d2",
    "folder-plus": "\f3d3",
    "folder-symlink-fill": "\f3d4",
    "folder-symlink": "\f3d5",
    "folder-x": "\f3d6",
    "folder": "\f3d7",
    "folder2-open": "\f3d8",
    "folder2": "\f3d9",
    "fonts": "\f3da",
    "forward-fill": "\f3db",
    "forward": "\f3dc",
    "front": "\f3dd",
    "fullscreen-exit": "\f3de",
    "fullscreen": "\f3df",
    "funnel-fill": "\f3e0",
    "funnel": "\f3e1",
    "gear-fill": "\f3e2",
    "gear-wide-connected": "\f3e3",
    "gear-wide": "\f3e4",
    "gear": "\f3e5",
    "gem": "\f3e6",
    "geo-alt-fill": "\f3e7",
    "geo-alt": "\f3e8",
    "geo-fill": "\f3e9",
    "geo": "\f3ea",
    "gift-fill": "\f3eb",
    "gift": "\f3ec",
    "github": "\f3ed",
    "globe": "\f3ee",
    "globe2": "\f3ef",
    "google": "\f3f0",
    "graph-down": "\f3f1",
    "graph-up": "\f3f2",
    "grid-1x2-fill": "\f3f3",
    "grid-1x2": "\f3f4",
    "grid-3x2-gap-fill": "\f3f5",
    "grid-3x2-gap": "\f3f6",
    "grid-3x2": "\f3f7",
    "grid-3x3-gap-fill": "\f3f8",
    "grid-3x3-gap": "\f3f9",
    "grid-3x3": "\f3fa",
    "grid-fill": "\f3fb",
    "grid": "\f3fc",
    "grip-horizontal": "\f3fd",
    "grip-vertical": "\f3fe",
    "hammer": "\f3ff",
    "hand-index-fill": "\f400",
    "hand-index-thumb-fill": "\f401",
    "hand-index-thumb": "\f402",
    "hand-index": "\f403",
    "hand-thumbs-down-fill": "\f404",
    "hand-thumbs-down": "\f405",
    "hand-thumbs-up-fill": "\f406",
    "hand-thumbs-up": "\f407",
    "handbag-fill": "\f408",
    "handbag": "\f409",
    "hash": "\f40a",
    "hdd-fill": "\f40b",
    "hdd-network-fill": "\f40c",
    "hdd-network": "\f40d",
    "hdd-rack-fill": "\f40e",
    "hdd-rack": "\f40f",
    "hdd-stack-fill": "\f410",
    "hdd-stack": "\f411",
    "hdd": "\f412",
    "headphones": "\f413",
    "headset": "\f414",
    "heart-fill": "\f415",
    "heart-half": "\f416",
    "heart": "\f417",
    "heptagon-fill": "\f418",
    "heptagon-half": "\f419",
    "heptagon": "\f41a",
    "hexagon-fill": "\f41b",
    "hexagon-half": "\f41c",
    "hexagon": "\f41d",
    "hourglass-bottom": "\f41e",
    "hourglass-split": "\f41f",
    "hourglass-top": "\f420",
    "hourglass": "\f421",
    "house-door-fill": "\f422",
    "house-door": "\f423",
    "house-fill": "\f424",
    "house": "\f425",
    "hr": "\f426",
    "hurricane": "\f427",
    "image-alt": "\f428",
    "image-fill": "\f429",
    "image": "\f42a",
    "images": "\f42b",
    "inbox-fill": "\f42c",
    "inbox": "\f42d",
    "inboxes-fill": "\f42e",
    "inboxes": "\f42f",
    "info-circle-fill": "\f430",
    "info-circle": "\f431",
    "info-square-fill": "\f432",
    "info-square": "\f433",
    "info": "\f434",
    "input-cursor-text": "\f435",
    "input-cursor": "\f436",
    "instagram": "\f437",
    "intersect": "\f438",
    "journal-album": "\f439",
    "journal-arrow-down": "\f43a",
    "journal-arrow-up": "\f43b",
    "journal-bookmark-fill": "\f43c",
    "journal-bookmark": "\f43d",
    "journal-check": "\f43e",
    "journal-code": "\f43f",
    "journal-medical": "\f440",
    "journal-minus": "\f441",
    "journal-plus": "\f442",
    "journal-richtext": "\f443",
    "journal-text": "\f444",
    "journal-x": "\f445",
    "journal": "\f446",
    "journals": "\f447",
    "joystick": "\f448",
    "justify-left": "\f449",
    "justify-right": "\f44a",
    "justify": "\f44b",
    "kanban-fill": "\f44c",
    "kanban": "\f44d",
    "key-fill": "\f44e",
    "key": "\f44f",
    "keyboard-fill": "\f450",
    "keyboard": "\f451",
    "ladder": "\f452",
    "lamp-fill": "\f453",
    "lamp": "\f454",
    "laptop-fill": "\f455",
    "laptop": "\f456",
    "layer-backward": "\f457",
    "layer-forward": "\f458",
    "layers-fill": "\f459",
    "layers-half": "\f45a",
    "layers": "\f45b",
    "layout-sidebar-inset-reverse": "\f45c",
    "layout-sidebar-inset": "\f45d",
    "layout-sidebar-reverse": "\f45e",
    "layout-sidebar": "\f45f",
    "layout-split": "\f460",
    "layout-text-sidebar-reverse": "\f461",
    "layout-text-sidebar": "\f462",
    "layout-text-window-reverse": "\f463",
    "layout-text-window": "\f464",
    "layout-three-columns": "\f465",
    "layout-wtf": "\f466",
    "life-preserver": "\f467",
    "lightbulb-fill": "\f468",
    "lightbulb-off-fill": "\f469",
    "lightbulb-off": "\f46a",
    "lightbulb": "\f46b",
    "lightning-charge-fill": "\f46c",
    "lightning-charge": "\f46d",
    "lightning-fill": "\f46e",
    "lightning": "\f46f",
    "link-45deg": "\f470",
    "link": "\f471",
    "linkedin": "\f472",
    "list-check": "\f473",
    "list-nested": "\f474",
    "list-ol": "\f475",
    "list-stars": "\f476",
    "list-task": "\f477",
    "list-ul": "\f478",
    "list": "\f479",
    "lock-fill": "\f47a",
    "lock": "\f47b",
    "mailbox": "\f47c",
    "mailbox2": "\f47d",
    "map-fill": "\f47e",
    "map": "\f47f",
    "markdown-fill": "\f480",
    "markdown": "\f481",
    "mask": "\f482",
    "megaphone-fill": "\f483",
    "megaphone": "\f484",
    "menu-app-fill": "\f485",
    "menu-app": "\f486",
    "menu-button-fill": "\f487",
    "menu-button-wide-fill": "\f488",
    "menu-button-wide": "\f489",
    "menu-button": "\f48a",
    "menu-down": "\f48b",
    "menu-up": "\f48c",
    "mic-fill": "\f48d",
    "mic-mute-fill": "\f48e",
    "mic-mute": "\f48f",
    "mic": "\f490",
    "minecart-loaded": "\f491",
    "minecart": "\f492",
    "moisture": "\f493",
    "moon-fill": "\f494",
    "moon-stars-fill": "\f495",
    "moon-stars": "\f496",
    "moon": "\f497",
    "mouse-fill": "\f498",
    "mouse": "\f499",
    "mouse2-fill": "\f49a",
    "mouse2": "\f49b",
    "mouse3-fill": "\f49c",
    "mouse3": "\f49d",
    "music-note-beamed": "\f49e",
    "music-note-list": "\f49f",
    "music-note": "\f4a0",
    "music-player-fill": "\f4a1",
    "music-player": "\f4a2",
    "newspaper": "\f4a3",
    "node-minus-fill": "\f4a4",
    "node-minus": "\f4a5",
    "node-plus-fill": "\f4a6",
    "node-plus": "\f4a7",
    "nut-fill": "\f4a8",
    "nut": "\f4a9",
    "octagon-fill": "\f4aa",
    "octagon-half": "\f4ab",
    "octagon": "\f4ac",
    "option": "\f4ad",
    "outlet": "\f4ae",
    "paint-bucket": "\f4af",
    "palette-fill": "\f4b0",
    "palette": "\f4b1",
    "palette2": "\f4b2",
    "paperclip": "\f4b3",
    "paragraph": "\f4b4",
    "patch-check-fill": "\f4b5",
    "patch-check": "\f4b6",
    "patch-exclamation-fill": "\f4b7",
    "patch-exclamation": "\f4b8",
    "patch-minus-fill": "\f4b9",
    "patch-minus": "\f4ba",
    "patch-plus-fill": "\f4bb",
    "patch-plus": "\f4bc",
    "patch-question-fill": "\f4bd",
    "patch-question": "\f4be",
    "pause-btn-fill": "\f4bf",
    "pause-btn": "\f4c0",
    "pause-circle-fill": "\f4c1",
    "pause-circle": "\f4c2",
    "pause-fill": "\f4c3",
    "pause": "\f4c4",
    "peace-fill": "\f4c5",
    "peace": "\f4c6",
    "pen-fill": "\f4c7",
    "pen": "\f4c8",
    "pencil-fill": "\f4c9",
    "pencil-square": "\f4ca",
    "pencil": "\f4cb",
    "pentagon-fill": "\f4cc",
    "pentagon-half": "\f4cd",
    "pentagon": "\f4ce",
    "people-fill": "\f4cf",
    "people": "\f4d0",
    "percent": "\f4d1",
    "person-badge-fill": "\f4d2",
    "person-badge": "\f4d3",
    "person-bounding-box": "\f4d4",
    "person-check-fill": "\f4d5",
    "person-check": "\f4d6",
    "person-circle": "\f4d7",
    "person-dash-fill": "\f4d8",
    "person-dash": "\f4d9",
    "person-fill": "\f4da",
    "person-lines-fill": "\f4db",
    "person-plus-fill": "\f4dc",
    "person-plus": "\f4dd",
    "person-square": "\f4de",
    "person-x-fill": "\f4df",
    "person-x": "\f4e0",
    "person": "\f4e1",
    "phone-fill": "\f4e2",
    "phone-landscape-fill": "\f4e3",
    "phone-landscape": "\f4e4",
    "phone-vibrate-fill": "\f4e5",
    "phone-vibrate": "\f4e6",
    "phone": "\f4e7",
    "pie-chart-fill": "\f4e8",
    "pie-chart": "\f4e9",
    "pin-angle-fill": "\f4ea",
    "pin-angle": "\f4eb",
    "pin-fill": "\f4ec",
    "pin": "\f4ed",
    "pip-fill": "\f4ee",
    "pip": "\f4ef",
    "play-btn-fill": "\f4f0",
    "play-btn": "\f4f1",
    "play-circle-fill": "\f4f2",
    "play-circle": "\f4f3",
    "play-fill": "\f4f4",
    "play": "\f4f5",
    "plug-fill": "\f4f6",
    "plug": "\f4f7",
    "plus-circle-dotted": "\f4f8",
    "plus-circle-fill": "\f4f9",
    "plus-circle": "\f4fa",
    "plus-square-dotted": "\f4fb",
    "plus-square-fill": "\f4fc",
    "plus-square": "\f4fd",
    "plus": "\f4fe",
    "power": "\f4ff",
    "printer-fill": "\f500",
    "printer": "\f501",
    "puzzle-fill": "\f502",
    "puzzle": "\f503",
    "question-circle-fill": "\f504",
    "question-circle": "\f505",
    "question-diamond-fill": "\f506",
    "question-diamond": "\f507",
    "question-octagon-fill": "\f508",
    "question-octagon": "\f509",
    "question-square-fill": "\f50a",
    "question-square": "\f50b",
    "question": "\f50c",
    "rainbow": "\f50d",
    "receipt-cutoff": "\f50e",
    "receipt": "\f50f",
    "reception-0": "\f510",
    "reception-1": "\f511",
    "reception-2": "\f512",
    "reception-3": "\f513",
    "reception-4": "\f514",
    "record-btn-fill": "\f515",
    "record-btn": "\f516",
    "record-circle-fill": "\f517",
    "record-circle": "\f518",
    "record-fill": "\f519",
    "record": "\f51a",
    "record2-fill": "\f51b",
    "record2": "\f51c",
    "reply-all-fill": "\f51d",
    "reply-all": "\f51e",
    "reply-fill": "\f51f",
    "reply": "\f520",
    "rss-fill": "\f521",
    "rss": "\f522",
    "rulers": "\f523",
    "save-fill": "\f524",
    "save": "\f525",
    "save2-fill": "\f526",
    "save2": "\f527",
    "scissors": "\f528",
    "screwdriver": "\f529",
    "search": "\f52a",
    "segmented-nav": "\f52b",
    "server": "\f52c",
    "share-fill": "\f52d",
    "share": "\f52e",
    "shield-check": "\f52f",
    "shield-exclamation": "\f530",
    "shield-fill-check": "\f531",
    "shield-fill-exclamation": "\f532",
    "shield-fill-minus": "\f533",
    "shield-fill-plus": "\f534",
    "shield-fill-x": "\f535",
    "shield-fill": "\f536",
    "shield-lock-fill": "\f537",
    "shield-lock": "\f538",
    "shield-minus": "\f539",
    "shield-plus": "\f53a",
    "shield-shaded": "\f53b",
    "shield-slash-fill": "\f53c",
    "shield-slash": "\f53d",
    "shield-x": "\f53e",
    "shield": "\f53f",
    "shift-fill": "\f540",
    "shift": "\f541",
    "shop-window": "\f542",
    "shop": "\f543",
    "shuffle": "\f544",
    "signpost-2-fill": "\f545",
    "signpost-2": "\f546",
    "signpost-fill": "\f547",
    "signpost-split-fill": "\f548",
    "signpost-split": "\f549",
    "signpost": "\f54a",
    "sim-fill": "\f54b",
    "sim": "\f54c",
    "skip-backward-btn-fill": "\f54d",
    "skip-backward-btn": "\f54e",
    "skip-backward-circle-fill": "\f54f",
    "skip-backward-circle": "\f550",
    "skip-backward-fill": "\f551",
    "skip-backward": "\f552",
    "skip-end-btn-fill": "\f553",
    "skip-end-btn": "\f554",
    "skip-end-circle-fill": "\f555",
    "skip-end-circle": "\f556",
    "skip-end-fill": "\f557",
    "skip-end": "\f558",
    "skip-forward-btn-fill": "\f559",
    "skip-forward-btn": "\f55a",
    "skip-forward-circle-fill": "\f55b",
    "skip-forward-circle": "\f55c",
    "skip-forward-fill": "\f55d",
    "skip-forward": "\f55e",
    "skip-start-btn-fill": "\f55f",
    "skip-start-btn": "\f560",
    "skip-start-circle-fill": "\f561",
    "skip-start-circle": "\f562",
    "skip-start-fill": "\f563",
    "skip-start": "\f564",
    "slack": "\f565",
    "slash-circle-fill": "\f566",
    "slash-circle": "\f567",
    "slash-square-fill": "\f568",
    "slash-square": "\f569",
    "slash": "\f56a",
    "sliders": "\f56b",
    "smartwatch": "\f56c",
    "snow": "\f56d",
    "snow2": "\f56e",
    "snow3": "\f56f",
    "sort-alpha-down-alt": "\f570",
    "sort-alpha-down": "\f571",
    "sort-alpha-up-alt": "\f572",
    "sort-alpha-up": "\f573",
    "sort-down-alt": "\f574",
    "sort-down": "\f575",
    "sort-numeric-down-alt": "\f576",
    "sort-numeric-down": "\f577",
    "sort-numeric-up-alt": "\f578",
    "sort-numeric-up": "\f579",
    "sort-up-alt": "\f57a",
    "sort-up": "\f57b",
    "soundwave": "\f57c",
    "speaker-fill": "\f57d",
    "speaker": "\f57e",
    "speedometer": "\f57f",
    "speedometer2": "\f580",
    "spellcheck": "\f581",
    "square-fill": "\f582",
    "square-half": "\f583",
    "square": "\f584",
    "stack": "\f585",
    "star-fill": "\f586",
    "star-half": "\f587",
    "star": "\f588",
    "stars": "\f589",
    "stickies-fill": "\f58a",
    "stickies": "\f58b",
    "sticky-fill": "\f58c",
    "sticky": "\f58d",
    "stop-btn-fill": "\f58e",
    "stop-btn": "\f58f",
    "stop-circle-fill": "\f590",
    "stop-circle": "\f591",
    "stop-fill": "\f592",
    "stop": "\f593",
    "stoplights-fill": "\f594",
    "stoplights": "\f595",
    "stopwatch-fill": "\f596",
    "stopwatch": "\f597",
    "subtract": "\f598",
    "suit-club-fill": "\f599",
    "suit-club": "\f59a",
    "suit-diamond-fill": "\f59b",
    "suit-diamond": "\f59c",
    "suit-heart-fill": "\f59d",
    "suit-heart": "\f59e",
    "suit-spade-fill": "\f59f",
    "suit-spade": "\f5a0",
    "sun-fill": "\f5a1",
    "sun": "\f5a2",
    "sunglasses": "\f5a3",
    "sunrise-fill": "\f5a4",
    "sunrise": "\f5a5",
    "sunset-fill": "\f5a6",
    "sunset": "\f5a7",
    "symmetry-horizontal": "\f5a8",
    "symmetry-vertical": "\f5a9",
    "table": "\f5aa",
    "tablet-fill": "\f5ab",
    "tablet-landscape-fill": "\f5ac",
    "tablet-landscape": "\f5ad",
    "tablet": "\f5ae",
    "tag-fill": "\f5af",
    "tag": "\f5b0",
    "tags-fill": "\f5b1",
    "tags": "\f5b2",
    "telegram": "\f5b3",
    "telephone-fill": "\f5b4",
    "telephone-forward-fill": "\f5b5",
    "telephone-forward": "\f5b6",
    "telephone-inbound-fill": "\f5b7",
    "telephone-inbound": "\f5b8",
    "telephone-minus-fill": "\f5b9",
    "telephone-minus": "\f5ba",
    "telephone-outbound-fill": "\f5bb",
    "telephone-outbound": "\f5bc",
    "telephone-plus-fill": "\f5bd",
    "telephone-plus": "\f5be",
    "telephone-x-fill": "\f5bf",
    "telephone-x": "\f5c0",
    "telephone": "\f5c1",
    "terminal-fill": "\f5c2",
    "terminal": "\f5c3",
    "text-center": "\f5c4",
    "text-indent-left": "\f5c5",
    "text-indent-right": "\f5c6",
    "text-left": "\f5c7",
    "text-paragraph": "\f5c8",
    "text-right": "\f5c9",
    "textarea-resize": "\f5ca",
    "textarea-t": "\f5cb",
    "textarea": "\f5cc",
    "thermometer-half": "\f5cd",
    "thermometer-high": "\f5ce",
    "thermometer-low": "\f5cf",
    "thermometer-snow": "\f5d0",
    "thermometer-sun": "\f5d1",
    "thermometer": "\f5d2",
    "three-dots-vertical": "\f5d3",
    "three-dots": "\f5d4",
    "toggle-off": "\f5d5",
    "toggle-on": "\f5d6",
    "toggle2-off": "\f5d7",
    "toggle2-on": "\f5d8",
    "toggles": "\f5d9",
    "toggles2": "\f5da",
    "tools": "\f5db",
    "tornado": "\f5dc",
    "trash-fill": "\f5dd",
    "trash": "\f5de",
    "trash2-fill": "\f5df",
    "trash2": "\f5e0",
    "tree-fill": "\f5e1",
    "tree": "\f5e2",
    "triangle-fill": "\f5e3",
    "triangle-half": "\f5e4",
    "triangle": "\f5e5",
    "trophy-fill": "\f5e6",
    "trophy": "\f5e7",
    "tropical-storm": "\f5e8",
    "truck-flatbed": "\f5e9",
    "truck": "\f5ea",
    "tsunami": "\f5eb",
    "tv-fill": "\f5ec",
    "tv": "\f5ed",
    "twitch": "\f5ee",
    "twitter": "\f5ef",
    "type-bold": "\f5f0",
    "type-h1": "\f5f1",
    "type-h2": "\f5f2",
    "type-h3": "\f5f3",
    "type-italic": "\f5f4",
    "type-strikethrough": "\f5f5",
    "type-underline": "\f5f6",
    "type": "\f5f7",
    "ui-checks-grid": "\f5f8",
    "ui-checks": "\f5f9",
    "ui-radios-grid": "\f5fa",
    "ui-radios": "\f5fb",
    "umbrella-fill": "\f5fc",
    "umbrella": "\f5fd",
    "union": "\f5fe",
    "unlock-fill": "\f5ff",
    "unlock": "\f600",
    "upc-scan": "\f601",
    "upc": "\f602",
    "upload": "\f603",
    "vector-pen": "\f604",
    "view-list": "\f605",
    "view-stacked": "\f606",
    "vinyl-fill": "\f607",
    "vinyl": "\f608",
    "voicemail": "\f609",
    "volume-down-fill": "\f60a",
    "volume-down": "\f60b",
    "volume-mute-fill": "\f60c",
    "volume-mute": "\f60d",
    "volume-off-fill": "\f60e",
    "volume-off": "\f60f",
    "volume-up-fill": "\f610",
    "volume-up": "\f611",
    "vr": "\f612",
    "wallet-fill": "\f613",
    "wallet": "\f614",
    "wallet2": "\f615",
    "watch": "\f616",
    "water": "\f617",
    "whatsapp": "\f618",
    "wifi-1": "\f619",
    "wifi-2": "\f61a",
    "wifi-off": "\f61b",
    "wifi": "\f61c",
    "wind": "\f61d",
    "window-dock": "\f61e",
    "window-sidebar": "\f61f",
    "window": "\f620",
    "wrench": "\f621",
    "x-circle-fill": "\f622",
    "x-circle": "\f623",
    "x-diamond-fill": "\f624",
    "x-diamond": "\f625",
    "x-octagon-fill": "\f626",
    "x-octagon": "\f627",
    "x-square-fill": "\f628",
    "x-square": "\f629",
    "x": "\f62a",
    "youtube": "\f62b",
    "zoom-in": "\f62c",
    "zoom-out": "\f62d",
    "bank": "\f62e",
    "bank2": "\f62f",
    "bell-slash-fill": "\f630",
    "bell-slash": "\f631",
    "cash-coin": "\f632",
    "check-lg": "\f633",
    "coin": "\f634",
    "currency-bitcoin": "\f635",
    "currency-dollar": "\f636",
    "currency-euro": "\f637",
    "currency-exchange": "\f638",
    "currency-pound": "\f639",
    "currency-yen": "\f63a",
    "dash-lg": "\f63b",
    "exclamation-lg": "\f63c",
    "file-earmark-pdf-fill": "\f63d",
    "file-earmark-pdf": "\f63e",
    "file-pdf-fill": "\f63f",
    "file-pdf": "\f640",
    "gender-ambiguous": "\f641",
    "gender-female": "\f642",
    "gender-male": "\f643",
    "gender-trans": "\f644",
    "headset-vr": "\f645",
    "info-lg": "\f646",
    "mastodon": "\f647",
    "messenger": "\f648",
    "piggy-bank-fill": "\f649",
    "piggy-bank": "\f64a",
    "pin-map-fill": "\f64b",
    "pin-map": "\f64c",
    "plus-lg": "\f64d",
    "question-lg": "\f64e",
    "recycle": "\f64f",
    "reddit": "\f650",
    "safe-fill": "\f651",
    "safe2-fill": "\f652",
    "safe2": "\f653",
    "sd-card-fill": "\f654",
    "sd-card": "\f655",
    "skype": "\f656",
    "slash-lg": "\f657",
    "translate": "\f658",
    "x-lg": "\f659",
    "safe": "\f65a",
    "apple": "\f65b",
    "microsoft": "\f65d",
    "windows": "\f65e",
    "behance": "\f65c",
    "dribbble": "\f65f",
    "line": "\f660",
    "medium": "\f661",
    "paypal": "\f662",
    "pinterest": "\f663",
    "signal": "\f664",
    "snapchat": "\f665",
    "spotify": "\f666",
    "stack-overflow": "\f667",
    "strava": "\f668",
    "wordpress": "\f669",
    "vimeo": "\f66a",
    "activity": "\f66b",
    "easel2-fill": "\f66c",
    "easel2": "\f66d",
    "easel3-fill": "\f66e",
    "easel3": "\f66f",
    "fan": "\f670",
    "fingerprint": "\f671",
    "graph-down-arrow": "\f672",
    "graph-up-arrow": "\f673",
    "hypnotize": "\f674",
    "magic": "\f675",
    "person-rolodex": "\f676",
    "person-video": "\f677",
    "person-video2": "\f678",
    "person-video3": "\f679",
    "person-workspace": "\f67a",
    "radioactive": "\f67b",
    "webcam-fill": "\f67c",
    "webcam": "\f67d",
    "yin-yang": "\f67e",
    "bandaid-fill": "\f680",
    "bandaid": "\f681",
    "bluetooth": "\f682",
    "body-text": "\f683",
    "boombox": "\f684",
    "boxes": "\f685",
    "dpad-fill": "\f686",
    "dpad": "\f687",
    "ear-fill": "\f688",
    "ear": "\f689",
    "envelope-check-1": "\f68a",
    "envelope-check-fill": "\f68b",
    "envelope-check": "\f68c",
    "envelope-dash-1": "\f68d",
    "envelope-dash-fill": "\f68e",
    "envelope-dash": "\f68f",
    "envelope-exclamation-1": "\f690",
    "envelope-exclamation-fill": "\f691",
    "envelope-exclamation": "\f692",
    "envelope-plus-fill": "\f693",
    "envelope-plus": "\f694",
    "envelope-slash-1": "\f695",
    "envelope-slash-fill": "\f696",
    "envelope-slash": "\f697",
    "envelope-x-1": "\f698",
    "envelope-x-fill": "\f699",
    "envelope-x": "\f69a",
    "explicit-fill": "\f69b",
    "explicit": "\f69c",
    "git": "\f69d",
    "infinity": "\f69e",
    "list-columns-reverse": "\f69f",
    "list-columns": "\f6a0",
    "meta": "\f6a1",
    "mortorboard-fill": "\f6a2",
    "mortorboard": "\f6a3",
    "nintendo-switch": "\f6a4",
    "pc-display-horizontal": "\f6a5",
    "pc-display": "\f6a6",
    "pc-horizontal": "\f6a7",
    "pc": "\f6a8",
    "playstation": "\f6a9",
    "plus-slash-minus": "\f6aa",
    "projector-fill": "\f6ab",
    "projector": "\f6ac",
    "qr-code-scan": "\f6ad",
    "qr-code": "\f6ae",
    "quora": "\f6af",
    "quote": "\f6b0",
    "robot": "\f6b1",
    "send-check-fill": "\f6b2",
    "send-check": "\f6b3",
    "send-dash-fill": "\f6b4",
    "send-dash": "\f6b5",
    "send-exclamation-1": "\f6b6",
    "send-exclamation-fill": "\f6b7",
    "send-exclamation": "\f6b8",
    "send-fill": "\f6b9",
    "send-plus-fill": "\f6ba",
    "send-plus": "\f6bb",
    "send-slash-fill": "\f6bc",
    "send-slash": "\f6bd",
    "send-x-fill": "\f6be",
    "send-x": "\f6bf",
    "send": "\f6c0",
    "steam": "\f6c1",
    "terminal-dash-1": "\f6c2",
    "terminal-dash": "\f6c3",
    "terminal-plus": "\f6c4",
    "terminal-split": "\f6c5",
    "ticket-detailed-fill": "\f6c6",
    "ticket-detailed": "\f6c7",
    "ticket-fill": "\f6c8",
    "ticket-perforated-fill": "\f6c9",
    "ticket-perforated": "\f6ca",
    "ticket": "\f6cb",
    "tiktok": "\f6cc",
    "window-dash": "\f6cd",
    "window-desktop": "\f6ce",
    "window-fullscreen": "\f6cf",
    "window-plus": "\f6d0",
    "window-split": "\f6d1",
    "window-stack": "\f6d2",
    "window-x": "\f6d3",
    "xbox": "\f6d4",
    "ethernet": "\f6d5",
    "hdmi-fill": "\f6d6",
    "hdmi": "\f6d7",
    "usb-c-fill": "\f6d8",
    "usb-c": "\f6d9",
    "usb-fill": "\f6da",
    "usb-plug-fill": "\f6db",
    "usb-plug": "\f6dc",
    "usb-symbol": "\f6dd",
    "usb": "\f6de",
    "boombox-fill": "\f6df",
    "displayport-1": "\f6e0",
    "displayport": "\f6e1",
    "gpu-card": "\f6e2",
    "memory": "\f6e3",
    "modem-fill": "\f6e4",
    "modem": "\f6e5",
    "motherboard-fill": "\f6e6",
    "motherboard": "\f6e7",
    "optical-audio-fill": "\f6e8",
    "optical-audio": "\f6e9",
    "pci-card": "\f6ea",
    "router-fill": "\f6eb",
    "router": "\f6ec",
    "ssd-fill": "\f6ed",
    "ssd": "\f6ee",
    "thunderbolt-fill": "\f6ef",
    "thunderbolt": "\f6f0",
    "usb-drive-fill": "\f6f1",
    "usb-drive": "\f6f2",
    "usb-micro-fill": "\f6f3",
    "usb-micro": "\f6f4",
    "usb-mini-fill": "\f6f5",
    "usb-mini": "\f6f6",
    "cloud-haze2": "\f6f7",
    "device-hdd-fill": "\f6f8",
    "device-hdd": "\f6f9",
    "device-ssd-fill": "\f6fa",
    "device-ssd": "\f6fb",
    "displayport-fill": "\f6fc",
    "mortarboard-fill": "\f6fd",
    "mortarboard": "\f6fe",
    "terminal-x": "\f6ff",
    "arrow-through-heart-fill": "\f700",
    "arrow-through-heart": "\f701",
    "badge-sd-fill": "\f702",
    "badge-sd": "\f703",
    "bag-heart-fill": "\f704",
    "bag-heart": "\f705",
    "balloon-fill": "\f706",
    "balloon-heart-fill": "\f707",
    "balloon-heart": "\f708",
    "balloon": "\f709",
    "box2-fill": "\f70a",
    "box2-heart-fill": "\f70b",
    "box2-heart": "\f70c",
    "box2": "\f70d",
    "braces-asterisk": "\f70e",
    "calendar-heart-fill": "\f70f",
    "calendar-heart": "\f710",
    "calendar2-heart-fill": "\f711",
    "calendar2-heart": "\f712",
    "chat-heart-fill": "\f713",
    "chat-heart": "\f714",
    "chat-left-heart-fill": "\f715",
    "chat-left-heart": "\f716",
    "chat-right-heart-fill": "\f717",
    "chat-right-heart": "\f718",
    "chat-square-heart-fill": "\f719",
    "chat-square-heart": "\f71a",
    "clipboard-check-fill": "\f71b",
    "clipboard-data-fill": "\f71c",
    "clipboard-fill": "\f71d",
    "clipboard-heart-fill": "\f71e",
    "clipboard-heart": "\f71f",
    "clipboard-minus-fill": "\f720",
    "clipboard-plus-fill": "\f721",
    "clipboard-pulse": "\f722",
    "clipboard-x-fill": "\f723",
    "clipboard2-check-fill": "\f724",
    "clipboard2-check": "\f725",
    "clipboard2-data-fill": "\f726",
    "clipboard2-data": "\f727",
    "clipboard2-fill": "\f728",
    "clipboard2-heart-fill": "\f729",
    "clipboard2-heart": "\f72a",
    "clipboard2-minus-fill": "\f72b",
    "clipboard2-minus": "\f72c",
    "clipboard2-plus-fill": "\f72d",
    "clipboard2-plus": "\f72e",
    "clipboard2-pulse-fill": "\f72f",
    "clipboard2-pulse": "\f730",
    "clipboard2-x-fill": "\f731",
    "clipboard2-x": "\f732",
    "clipboard2": "\f733",
    "emoji-kiss-fill": "\f734",
    "emoji-kiss": "\f735",
    "envelope-heart-fill": "\f736",
    "envelope-heart": "\f737",
    "envelope-open-heart-fill": "\f738",
    "envelope-open-heart": "\f739",
    "envelope-paper-fill": "\f73a",
    "envelope-paper-heart-fill": "\f73b",
    "envelope-paper-heart": "\f73c",
    "envelope-paper": "\f73d",
    "filetype-aac": "\f73e",
    "filetype-ai": "\f73f",
    "filetype-bmp": "\f740",
    "filetype-cs": "\f741",
    "filetype-css": "\f742",
    "filetype-csv": "\f743",
    "filetype-doc": "\f744",
    "filetype-docx": "\f745",
    "filetype-exe": "\f746",
    "filetype-gif": "\f747",
    "filetype-heic": "\f748",
    "filetype-html": "\f749",
    "filetype-java": "\f74a",
    "filetype-jpg": "\f74b",
    "filetype-js": "\f74c",
    "filetype-jsx": "\f74d",
    "filetype-key": "\f74e",
    "filetype-m4p": "\f74f",
    "filetype-md": "\f750",
    "filetype-mdx": "\f751",
    "filetype-mov": "\f752",
    "filetype-mp3": "\f753",
    "filetype-mp4": "\f754",
    "filetype-otf": "\f755",
    "filetype-pdf": "\f756",
    "filetype-php": "\f757",
    "filetype-png": "\f758",
    "filetype-ppt-1": "\f759",
    "filetype-ppt": "\f75a",
    "filetype-psd": "\f75b",
    "filetype-py": "\f75c",
    "filetype-raw": "\f75d",
    "filetype-rb": "\f75e",
    "filetype-sass": "\f75f",
    "filetype-scss": "\f760",
    "filetype-sh": "\f761",
    "filetype-svg": "\f762",
    "filetype-tiff": "\f763",
    "filetype-tsx": "\f764",
    "filetype-ttf": "\f765",
    "filetype-txt": "\f766",
    "filetype-wav": "\f767",
    "filetype-woff": "\f768",
    "filetype-xls-1": "\f769",
    "filetype-xls": "\f76a",
    "filetype-xml": "\f76b",
    "filetype-yml": "\f76c",
    "heart-arrow": "\f76d",
    "heart-pulse-fill": "\f76e",
    "heart-pulse": "\f76f",
    "heartbreak-fill": "\f770",
    "heartbreak": "\f771",
    "hearts": "\f772",
    "hospital-fill": "\f773",
    "hospital": "\f774",
    "house-heart-fill": "\f775",
    "house-heart": "\f776",
    "incognito": "\f777",
    "magnet-fill": "\f778",
    "magnet": "\f779",
    "person-heart": "\f77a",
    "person-hearts": "\f77b",
    "phone-flip": "\f77c",
    "plugin": "\f77d",
    "postage-fill": "\f77e",
    "postage-heart-fill": "\f77f",
    "postage-heart": "\f780",
    "postage": "\f781",
    "postcard-fill": "\f782",
    "postcard-heart-fill": "\f783",
    "postcard-heart": "\f784",
    "postcard": "\f785",
    "search-heart-fill": "\f786",
    "search-heart": "\f787",
    "sliders2-vertical": "\f788",
    "sliders2": "\f789",
    "trash3-fill": "\f78a",
    "trash3": "\f78b",
    "valentine": "\f78c",
    "valentine2": "\f78d",
    "wrench-adjustable-circle-fill": "\f78e",
    "wrench-adjustable-circle": "\f78f",
    "wrench-adjustable": "\f790",
    "filetype-json": "\f791",
    "filetype-pptx": "\f792",
    "filetype-xlsx": "\f793",
);

$modal-content-border-radius: 0.25rem;
$modal-types: (
    "question": (
        icon: "question-lg",
        color-name: "info",
        color: map.get($theme-colors, "info"),
        accent: map.get($theme-accents, "info")
    ),
    "success": (
        icon: "check-lg",
        color-name: "success",
        color: map.get($theme-colors, "success"),
        accent: map.get($theme-accents, "success")
    ),
    "error": (
        icon: "exclamation-lg",
        color-name: "danger",
        color: map.get($theme-colors, "danger"),
        accent: map.get($theme-accents, "danger")
    ),
    "info": (
        icon: "info-lg",
        color-name: "accent1",
        color: map.get($theme-colors, "accent1"),
        accent: map.get($theme-accents, "accent1")
    ),
    "interactive-settings": (
        icon: "lightning-fill",
        color-name: "accent3",
        color: map.get($theme-colors, "accent3"),
        accent: map.get($theme-accents, "accent3")
    ),
    "image-settings" : (
        icon: "image",
        color-name: "accent3",
        color: map.get($theme-colors, "accent3"),
        accent: map.get($theme-accents, "accent3")
    )
);
